import React, { useEffect, useState } from "react";
import { styled, TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SubmitButton from "../SubmitButton";
import { useKeycloak } from "@react-keycloak/web";
import { resetDevice, SERVER_IP, setDeviceImageCaptureInterval, setDeviceImageCaptureTime, setDeviceWifiCredentials, updateDevice } from "../../../services/api/api";
import { useAppContext } from "../../../store/AppContextProvider";
import { useDevice, useMyDevices } from "../../../services/api/swrHooks";
import { mapDevices } from "../../../utils/helperFunctions";
import dayjs, { Dayjs } from "dayjs";

interface PhenodeSettingsProps {
    name: string;
    defaultName: string;
}

function CalendarIcon() {
    return <InsertInvitationIcon style={{ color: "white" }} />;
}
const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "#1a75e0",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1a75e0",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1a75e0",
        },
        "&:hover fieldset": {
            borderColor: "#1a75e0",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1a75e0",
        },
    },
    "& input": {
        color: "#48f7f5",
        // fontSize: "12px",
    },
    "& label": {
        color: "#48f7f5",
        // fontSize: "12px",
    },
});

function unsecuredCopyToClipboard(text: any) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand("copy");
    } catch (err) {
        console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
}

export default function SettingsContainer(props: PhenodeSettingsProps) {
    // const { name, defaultName } = props;

    const { keycloak } = useKeycloak();

    
    const { selectedDevice, setSelectedDevice, setDeviceList } = useAppContext();
    const [newName, setNewName] = useState("");
    const [token, setToken] = useState("");
    const [tempName, setTempName] = useState("");
    const [captureInterval, setCaptureInterval] = useState(1);
    const [ssid, setSsid] = useState("");
    const [password, setPassword] = useState("");
    const [selectedTime, setSelectedTime] = useState<Dayjs | null>(dayjs());
    const [settingName, setSettingName] = useState<boolean>(false);
    const [doneSettingName, setDoneSettingName] = useState<boolean>(false);
    const [resetDisabled, setResetDisabled] = useState<boolean>(true);

    const { devicesData, devicesLoading, mutate } = useMyDevices(token);
    
    const { data, deviceIsLoading, deviceIsError } = useDevice(token, selectedDevice);


    // Update name on new device selected
    useEffect(() => {
        if (data) {
            if (data.device?.label)
                setTempName(data.device.label);

            // Check fw version
            if (data?.device?.fwVersion) {
                // Parse version and check major, minor, patch
                let fwVersion = data.device.fwVersion;
                fwVersion = fwVersion.replace("v", "");
                const major = parseInt(fwVersion.split(".")[0]);
                const minor = parseInt(fwVersion.split(".")[1]);
                const patch = parseInt(fwVersion.split(".")[2]);

                if (major == 0) {
                    // Version v0.15.0 onwards contains the correct firmware
                    if (minor >= 15) {
                        setResetDisabled(false);
                    }
                }

                if (major > 0) {
                    setResetDisabled(false);
                }
            }
        }



    }, [data]);

    useEffect(() => {
        if (settingName) {
            alert("Click to confirm name change");
        }
        else {
            if (doneSettingName) {
                alert("Successfully updated device name");
                setDoneSettingName(false);
            }
        }
    }, [settingName]);

    useEffect(() => {
        if (keycloak.token) {
            setToken(keycloak.token);
        }
    }, [keycloak.token]);

    useEffect(() => {
        if (newName !== "" && newName !== data.device?.label) {
            const fetchData = async () => {
                setSettingName(true);
                const data = await updateDevice(selectedDevice, token, { label: newName });
                const resp = await data.json();
                if (resp.success) {
                    await mutate();
                    setTempName(newName);
                    setSelectedDevice(selectedDevice);
                }
                setSettingName(false);
                setDoneSettingName(true);
            };
            fetchData().catch((e) => {
                console.log("Error updating device label", e);
            });
        }
    }, [newName]);

    // Update device list on name change
    useEffect(() => {
        if (devicesData) {
            setDeviceList(mapDevices(devicesData.devices));
        }
    }, [devicesData]);

    const handleNameChange = (event: any) => {
        setTempName(event.target.value);
    };

    const handleIntervalChange = (event: any) => {
        setCaptureInterval(event.target.value);
    };

    const handleSsidChange = (event: any) => {
        setSsid(event.target.value);
    };

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
    };

    const onClickUpdatePhenode = () => {
        alert("Device update is currently not yet supported");
    };

    const onClickRename = () => {
        if (tempName === "") {
            alert("Please enter a valid name");
        }
        else {
            setNewName(tempName);
        }
    };

    const onClickSetInterval = async () => {

        const resp = await setDeviceImageCaptureInterval(selectedDevice, token, { camera_capture_times_per_day: captureInterval});

        if (resp.status === 200) {
            alert("Successfully written capture interval to NoteHub. Wait for the device to pick up the changes and restart.");
        }
        else {
            alert("Failed to set wifi credentials");
        }
    };

    const onClickSetTime = async () => {

        const resp = await setDeviceImageCaptureTime(selectedDevice, token, { camera_capture_hour: `${selectedTime?.hour()}`, camera_capture_min: `${selectedTime?.minute()}`});

        if (resp.status === 200) {
            alert("Successfully written image capture time to NoteHub. Wait for the device to pick up the changes and restart.");
        }
        else {
            alert("Failed to set wifi credentials");
        }
    };

    const onClickSetWifiCredentials = async () => {
        // alert("Setting the wifi credentials is currently not yet supported");
        const resp = await setDeviceWifiCredentials(selectedDevice, token, { wifi_ssid: ssid, wifi_password: password });

        if (resp.status === 200) {
            alert("Successfully written wifi credentials to NoteHub. Wait for the device to pick up the changes and restart.");
        }
        else {
            alert("Failed to set wifi credentials");
        }
    };

    const onClickResetDevice = async () => {
        const resp = await resetDevice(selectedDevice, token, { _aux_gpio_set: `,,,high,100,${Math.floor(Date.now() / 1000)},7200` });

        if (resp.status === 200) {
            alert("Successfully reset device. Wait for the device to pick up the changes and restart.");
        }
        else {
            alert("Failed to reset device");
        }
    };

    if (deviceIsLoading) return <div className="loading-text">Loading...</div>;
    else {

        return (
            <div id="w-node-b3a5726e-64c9-ea46-47b2-82a6988a93ed-46ac755d" className="div-block-47">
                <div id="w-node-_452760b4-602e-e1eb-ce2c-fcf985adab30-46ac755d" className="w-layout-grid basic-account-grid">
                    <div id="w-node-textblock-46ac755d" className="general-div-label settings">
                        Software Update
                    </div>
                    <div></div>
                    <SubmitButton label={"Update"} onClick={onClickUpdatePhenode}></SubmitButton>
                </div>
                <div id="w-node-_18cf0418-027a-861a-f0c1-4ed5243ca4a2-46ac755d" className="w-layout-grid basic-account-grid">
                    <div className="general-div-label settings">{selectedDevice}</div>
                    <div id="w-node-_18cf0418-027a-861a-f0c1-4ed5243ca4a5-46ac755d" className="general-div-label settings">
                        Rename this PheNode
                    </div>
                    <CssTextField value={tempName} style={{ marginTop: "10px", width: "100%" }} size="small" onChange={handleNameChange} />
                    <SubmitButton label={"Rename"} onClick={onClickRename} sx={{marginTop: "10px"}} disabled={settingName}></SubmitButton>
                </div>
                <div id="w-node-_18cf0418-027a-861a-f0c1-4ed5243ca4a2-46ac755f" className="w-layout-grid basic-account-grid">
                    <div id="w-node-_18cf0418-027a-861a-f0c1-4ed5243ca4a5-46ac755d" className="general-div-label settings">
                        Set image capture interval (imgs/day)
                    </div>
                    <CssTextField value={captureInterval} style={{ marginTop: "10px", width: "100%" }} size="small" onChange={handleIntervalChange} />
                    <SubmitButton label={"Set Interval"} onClick={onClickSetInterval} sx={{marginTop: "10px"}}></SubmitButton>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div id="w-node-_18cf0418-027a-861a-f0c1-4ed5243ca4a2-46ac755g" className="w-layout-grid basic-account-grid">
                        <div id="w-node-_18cf0418-027a-861a-f0c1-4ed5243ca4a5-46ac755d" className="general-div-label settings">
                            Set image capture time
                        </div>
                        <TimePicker
                            components={{ OpenPickerIcon: CalendarIcon }}
                            value={selectedTime}
                            onChange={(newTime) => setSelectedTime(newTime)}
                            sx={{
                                border: 1,
                                borderColor: "#f59331",
                                height: "50px",
                                borderRadius: "2px",
                                "& input": { color: "#48f7f5", fontSize: "1rem", fontFamily: "Arial, Helvetica Neue, Helvetica, sans-serif", paddingTop: "14px" },
                            }}></TimePicker>
                        <SubmitButton label={"Set Time"} onClick={onClickSetTime} sx={{marginTop: "10px"}}></SubmitButton>
                    </div>
                </LocalizationProvider>
                <div id="w-node-_452760b4-602e-e1eb-ce2c-fcf985adab30-46ac755d" className="w-layout-grid basic-account-grid">
                    <div id="w-node-textblock-46ac755d" className="general-div-label settings">
                        Get API Access Token
                    </div>
                    <div></div>
                    <SubmitButton
                        label={"Copy Token to Clipboard"}
                        onClick={() => {
                            if (navigator.clipboard) {
                                navigator.clipboard.writeText(token);
                                alert("Token copied to clipboard");
                            } else {
                                unsecuredCopyToClipboard(token);
                                alert("Token copied to clipboard");
                            }
                        }}></SubmitButton>
                </div>
                <div id="w-node-_452760b4-602e-e1eb-ce2c-fcf985adab30-46ac755d" className="w-layout-grid basic-account-grid">
                    <div id="w-node-textblock-46ac755d" className="general-div-label settings">
                        Manage Account
                    </div>
                    <div></div>
                    <SubmitButton
                        label={"Manage Account"}
                        onClick={() => window.open("https://auth.phenode.live/realms/grafana/account/", "_blank")}></SubmitButton>
                </div>
                <div id="w-node-_18cf0418-027a-861a-f0c1-4ed5243ca4a2-46ac755f" className="w-layout-grid basic-account-grid">
                    <div id="w-node-_18cf0418-027a-861a-f0c1-4ed5243ca4a5-46ac755d" className="general-div-label settings">
                        Set Wi-Fi credentials
                    </div>
                    <CssTextField value={ssid} placeholder={"SSID"} style={{ marginTop: "10px", width: "100%" }} size="small" onChange={handleSsidChange} />
                    <CssTextField value={password} placeholder={"Password"} style={{ marginTop: "10px", width: "100%" }} size="small" onChange={handlePasswordChange} />
                    <SubmitButton label={"Set Wi-Fi credentials"} onClick={onClickSetWifiCredentials} sx={{marginTop: "10px"}}></SubmitButton>
                </div>
                <div id="w-node-_18cf0418-027a-861a-f0c1-4ed5243ca4a2-46ac755f" className="w-layout-grid basic-account-grid">
                    <div id="w-node-_18cf0418-027a-861a-f0c1-4ed5243ca4a5-46ac755d" className="general-div-label settings">
                        Reset device
                    </div>
                    <div></div>
                    <SubmitButton disabled={resetDisabled} sx={resetDisabled ? {"&&": {color: "#ccc", borderColor: "#ccc"}} : {}} label={"Reset Device"} onClick={onClickResetDevice}></SubmitButton>
                </div>
            </div>
        );
    }
}
