import React, { useCallback, useEffect, useState } from "react";
import "./real-time.modules.css";
import { Navigate } from "react-router-dom";
import Layout from "../../components/Layout";
import NavBlock from "../../components/NavBlock";
import { useDevice, useMySensors } from "../../services/api/swrHooks";
import AmbientAir from "./components/AmbientAir";
import Grafana from "./components/Grafana";
import ImagingSensor from "./components/ImagingSensor";
import Rainfall from "./components/Rainfall";
import ScreenTitle from "./components/ScreenTitle";
import SoilSensor1 from "./components/SoilSensor1";
import SoilSensor2 from "./components/SoilSensor2";
import System from "./components/System";
import WindSensor from "./components/WindSensor";
import WirelessSensor from "./components/WirelessSensor";
import GrafanaActiveIcon from "../../assets/images/Grafana-Icon_Active.svg";
import { Device } from "../../utils/typesAndInterfaces";
import { useAppContext } from "../../store/AppContextProvider";
import { formatGpsData } from "../../utils/helperFunctions";
import { useKeycloak } from "@react-keycloak/web";
import PheNodeGraphic from "../../assets/images/PheNode-Sensor-Frame.svg";
import ScreenToggleButton from "../../components/NavBlock/ScreenToggleButtons/ScreenToggleButton";
import { DASHBOARD_ID, SERVER_IP } from "../../services/api/api";
import Atmos41Sensor from "./components/Atmos41";

export default function RealTimeDataPage() {
    const { selectedDevice } = useAppContext();
    const { keycloak } = useKeycloak();
    const { data, deviceIsLoading, deviceIsError } = useDevice(keycloak.token, selectedDevice);
    const [device, setDevice] = useState<Device | null>(null);
    const { sensorsData, sensorsLoading, sensorsError } = useMySensors(keycloak.token);
    const [sensors, setSensors] = useState<string[] | null>(null);

    useEffect(() => {
        if (sensorsData) {
            setSensors(sensorsData.sensors);
        }
    }, [sensorsData]);

    useEffect(() => {
        if (data) {
            //console.log("Full device data:", data.device); // Log the full device object
            setDevice(data.device);
        }
    }, [data]);

    const windSensor = device?.windSensor;
    const rainfallSensor = device?.rainfallSensor;
    const airSensor = device?.airSensor;
    const camera = device?.camera;
    const soilSensor1 = device?.soilSensors[0];
    const soilSensor2 = device?.soilSensors[1];
    const atmos41 = device?.atmos41Sensor;
    const battery = device?.battery;
    const rf = device?.rf;
    const connectedSensors = device?.connectedSensors;

    const lat = device?.gps.latitude ? device.gps.latitude : null;
    const lon = device?.gps.longitude ? device.gps.longitude : null;
    const gpsFormatted = formatGpsData(lon, lat);

    const lastMeasurement = device?.lastMeasurement ? new Date(device.lastMeasurement).toLocaleString() : "No data";

    const deviceLabel = device?.label ? device.label : "PheNode";

    const sensorsUrl = sensors ? "&var-sensor=" + sensors.map((sensor: any) => sensor.externalSensorId).join("&var-sensor=") : "&var-sensor=";
    const grafanaUrl = `https://grafana.phenode.live/${DASHBOARD_ID}?orgId=1&kiosk=tv&auth_token=${keycloak.token}&refresh=30m&from=now-6h&to=now&var-device=${selectedDevice}${sensorsUrl}`;

   
    // Add console logs to check the values of wifi and cellular
    //console.log("WiFi Signal Strength:", rf?.wifiSignalStrength);
    //console.log("Cellular Signal Strength:", rf?.cellularSignalStrength);


    if (!selectedDevice) {
        return <Navigate to="/" />;
    }

    if (deviceIsLoading) return <div className="loading-text">Loading...</div>;
    else {
        return (
            <Layout>
                <div className="w-layout-grid main-grid real-time">
                    <NavBlock col={5} fleet_view={false} gpsFormatted={gpsFormatted} lastMeasurement={lastMeasurement} selectedDevice={deviceLabel} />
                    <ScreenTitle deviceLabel={deviceLabel} />
                    <div id="phenode-status-div" className="phenode-status-div">
                        <Rainfall hourlyRainfall={rainfallSensor?.hourlyRainfall} sensorHealth={rainfallSensor?.sensorHealth} />
                        <WindSensor windSpeed={windSensor?.windSpeed} windGustSpeed={windSensor?.windGustSpeed} windDirection={windSensor?.windDirection} sensorHealth={windSensor?.sensorHealth} />
                        {/* <WirelessSensor connectedSensors={connectedSensors} /> */}
                        <AmbientAir temperature={airSensor?.temperature} humidity={airSensor?.humidity} airPressure={airSensor?.airPressure} vaporPressure={airSensor?.vaporPressure} sensorHealth={airSensor?.sensorHealth} />
                        <ImagingSensor lastImageSent={camera?.lastImage} sensorHealth={camera?.cameraHealth} />
                        <SoilSensor1 electricalConductivity={soilSensor1?.electricalConductivity} vwc={soilSensor1?.vwc} soilMoisture={soilSensor1?.soilMoisture} soilTemperature={soilSensor1?.soilTemperature} sensorHealth={soilSensor1?.sensorHealth} />
                        <SoilSensor2 electricalConductivity={soilSensor2?.electricalConductivity} vwc={soilSensor2?.vwc} soilMoisture={soilSensor2?.soilMoisture} soilTemperature={soilSensor2?.soilTemperature} sensorHealth={soilSensor2?.sensorHealth} />
                        <System batteryPercent={battery?.batteryPercent} health={battery?.batteryHealth} wifi={rf?.wifiSignalStrength} cellular={rf?.cellularSignalStrength} />
                        <Atmos41Sensor precipitation={atmos41?.precipitation} solar={atmos41?.solar} strikeDistance={atmos41?.strikeDistance} strikes={atmos41?.strikes} metadataValue={atmos41?.metadataValue} />
                        <img
                            src={PheNodeGraphic}
                            loading="eager"
                            id="w-node-afc0e37e-ff63-608b-825e-3bfc65b0cfce-07ac755a"
                            alt=""
                            className="phenode-diagram-graphic"
                        />
                        <ScreenToggleButton
                            key="grafana-screen-button"
                            id="grafana-screen-button-real-time"
                            className="grafana-screen-button"
                            to={grafanaUrl}
                            sx={{ gridRowStart: 7, width: "10rem" }}
                            icon={GrafanaActiveIcon}
                            activeIcon={GrafanaActiveIcon}
                            disabled={false}
                        />
                    </div>
                    <Grafana />
                </div>
            </Layout>
        );
    }
}
